import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import StatsComponent from "@components/admin/stats/StatsComponent";
import { NormalizedStat, normalizeStats } from "@components/admin/stats/StatsHelper";
import { queryApi } from "@hooks/api";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { Countries } from "@reshopper/admin-client";
import { endOfDay, isAfter, isEqual, setHours, startOfDay, subMonths } from "date-fns";
import { useCallback, useEffect, useState } from "react";

export default withAdminMenuLayout(function StatsPageComponent(): JSX.Element {
	const now = new Date();
	const [fromDate, setFromDate] = useState<Date>(subMonths(startOfDay(now), 1));
	const [toDate, setToDate] = useState<Date>(endOfDay(now));
	const [country, setCountry] = useState<Countries | "">("dk");
	const [stats, setStats] = useState<NormalizedStat[] | undefined>(undefined);


	const isInvalidDateRange = useCallback(() => {
		return !fromDate || !toDate || isAfter(fromDate, startOfDay(new Date()));
	}, [fromDate]);

	useEffect(() => {
		if (isInvalidDateRange()) {
			setStats(undefined);
		}
		else {
			setStatsFromDate();
		}

		async function setStatsFromDate() {
			const stats = await adminClient().adminStatsTimeSeriesGet({
				fromDate: setHours(startOfDay(fromDate), 12),
				toDate: setHours(startOfDay(toDate), 12)
			});
			setStats(normalizeStats(stats.dailyStats));
		}
	},
		[fromDate, toDate, isInvalidDateRange, country]
	)

	const [listOfCountries] = queryApi(
		async (options) => await adminClient().adminUserAddressListOfValidCountriesGet(options),
		[]);

	function onCountryChange(event: React.ChangeEvent<{ value: unknown }>) {
		setCountry(event.target.value as Countries | "");
	}

	const spacing = 2;

	function onSubmit(from: Date | null, to: Date | null) {
		if ((!from || !to) || (isEqual(from, fromDate) && isEqual(to, toDate))) {
			return;
		}
		setStats(undefined);
		setFromDate(from);
		setToDate(to);
	}

	return <Box>
		<Box mb={spacing}>
			<Typography variant="h4">
				Item stats
			</Typography>
		</Box>
		<Box mb={spacing}>
			<Box
				display="flex"
				flexDirection="reverse-row"
				alignItems="center"
				flexWrap="nowrap"
				justifyContent="flex-end"
			>
				<Box flexGrow={1} />
				<Box mb={spacing}>
					<FormControl
						fullWidth={true} style={{
							minWidth: "100px"
						}}>
						<InputLabel>
							Countries
						</InputLabel>
						<Select
							style={{
								marginRight: 16
							}}
							native={false}
							value={country}
							onChange={(e) => onCountryChange(e)}>
							<MenuItem value="">
								All
							</MenuItem>
							{listOfCountries && listOfCountries.countries?.map((c, index) => {
								return <MenuItem value={c.toLowerCase()} key={index}>
									{c.toUpperCase()}
								</MenuItem>
							})}
						</Select>
					</FormControl>
				</Box>
				<DateRangePickerComponent
					onSubmit={onSubmit}
					renderTimeOption={false}
					renderDateRanges={true}
					disableFutureDates={true}
					disablePastDates={false}
					submitOnLoad={false}
					variant="outlined"
					defaultFromDate={fromDate}
					defaultToDate={toDate}
				/>
			</Box>
		</Box>
		{isInvalidDateRange()
			? <div>Date Range is invalid</div>
			: <>
				{!!stats ?
					<><Box mb={spacing}>
						<StatsComponent
							country={country}
							stats={stats}
							fromDate={fromDate}
							toDate={toDate}
							keysForGraphs={keysForItemStats}
						/>
					</Box>
					</>
					: null}
			</>}
	</Box>
})

const keysForItemStats = [
	{
		key: "itemViewed",
		title: "Item views",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemCreated",
		title: "New",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemSold",
		title: "Sold",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemDeleted",
		title: "Deleted",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemMedianDailySoldTimeInHours",
		title: "Sold time (median, in hours)",
		hasGraph: true,
		hasTotal: false
	}
];